<template>
  <div class="pdf">
    <div class="box1">
      <Headers></Headers>
      <p class="arrow">
        <!-- // 上一页 -->
        <span
          @click="changePdfPage(0)"
          class="turn"
          :class="{ grey: currentPage == 1 }"
          >{{$t('head.shangye')}}</span
        >
        {{ currentPage }} / {{ pageCount }}
        <!-- // 下一页 -->
        <span
          @click="changePdfPage(1)"
          class="turn"
          :class="{ grey: currentPage == pageCount }"
          >{{$t('head.xiaye')}}</span
        >
      </p>
    </div>
    <div class="pafbox">
      <pdf
        :src="$route.query.userId"
        :page="currentPage"
        @loaded="loadPdfHandler"
        @page-loaded="currentPage = $event"
        @num-pages="pageCount = $event"
      ></pdf>
    </div>
  </div>
</template> 
<script>
import pdf from "vue-pdf";
import Headers from "./headers.vue";
export default {
  data() {
    return {
      currentPage: 0, // pdf文件页码
      pageCount: 0, // pdf文件总页数
      fileType: "pdf", // 文件类型
    };
  },
  methods: {
    changePdfPage(val) {
      // console.log(val)
      if (val === 0 && this.currentPage > 1) {
        this.currentPage--;
      }
      if (val === 1 && this.currentPage < this.pageCount) {
        this.currentPage++;
      }
    },
    loadPdfHandler(e) {
      this.currentPage = 1; // 加载的时候先加载第一页
    },
  },
  components: {
    pdf,
    Headers,
  },
};
</script>



<style lang="scss">
.pdf {
  overflow: hidden;
  .box1 {
      display: flex;
      justify-content: space-between;
      align-items: center;
    .arrow {
      text-align: right;
      color: #fff;
  margin-bottom: 40px;
      .turn {
        cursor: pointer;
        font-weight: bold;
        font-size: 20px;
      }
    }
  }
  .pafbox {
    margin-bottom: 40px;
    span {
      display: block !important;
      canvas {
        width: 100% !important;
        min-height: 650px !important;
      }
    }
  }
}
</style>